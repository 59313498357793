@import '~@/styles/preprocessed';

.admin {
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: $grey-0;
  .tracks {
    margin-top: $header-height+55!important;
  }
  .dock {
    display: flex;
    width: 100%;
    align-items: flex-start;
    margin: 0;
    button {
      flex-grow: 1;
    }
    @include breakpoint('tablet') {
      display: block;
      width: auto;
    }
  }
}
