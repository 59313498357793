@import '~@/styles/preprocessed';

.track-details {
  justify-content: center;
  &--init {
    .track-details__play .play-button {
      &__left {
        clip-path: polygon(0 0, 50% 25%, 50% 75%, 0% 100%);
      }
      &__right {
        clip-path: polygon(50% 25%, 100% 50%, 100% 50%, 50% 75%);
      } 
    }
  }
  .play-button {
    &.style-1 {
      .play-button__right, .play-button__left {
        background-color: $color-1;
      }
    }
    &.style-2 {
      .play-button__right, .play-button__left {
        background-color: $color-2;
      }
    }
    &.style-3 {
      .play-button__right, .play-button__left {
        background-color: $color-3;
      }
    }
  }
  &__title, &__img {
    cursor: pointer;
  }
  &__user__link {
    text-align: right;
    font-size: 18px;
    img {
      padding-right: 10px;
    }
  }
  &__img {
    width: 90%;
    @include breakpoint(tablet) {
      width: 600px;
    }
  }
  &__picture {
    position: relative;
    display: flex;
    place-content: center;
    align-content: center;
    justify-content: center;
  }
  &__play {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  .loader {
    position: absolute;
  }
  p {
    font-size: 16px;
    font-weight: bold;
    display: block;
  }
}
