@import '~@/styles/preprocessed';

.tracks {
  z-index: $z-index-tracks;
  width: 100%;
  border-collapse: collapse;
  &--show-duration.tracks .track__duration {
    display: inline;
  }
  .no-track__txt {
    text-align: center;
  }
  &__add-tracks {
    margin: 12px;
    color: rgb(37, 37, 37);
    background-color: $youtube-red;
    // text-transform: uppercase;
    font-weight: bold;
    transition: all .3s;
    &:hover {
      color: $youtube-red;
      border-radius: 8px;
    }
    .ion {
      padding-right: 16px;
    }
  }
  .loader {
    display: flex;
    width: 60px;
    height: 60px;
    position: fixed;
    top: calc(50vh - 60px);
    left: calc(50vw - 60px);
  }
  .track {
    .app--nav & {
      @include breakpoint(phone, only) {
        display: none;
      }
    }
    cursor: default;
    border-bottom: 1px rgba(255, 255, 255, 0.1) solid;
    height: 50px;
    filter: grayscale(0);
    // transition: all 0.2s;
    &:hover {
      filter: brightness(1.3);
    }
    &:active {
      opacity: 0.5;
    }
    &:focus {
      background-color: $color-selection;
    }
    background-color: transparent;
    .play {
      display: flex;
      cursor: pointer;
    }
    .pause {
      display: none;
    }
    &--playing {
      &.track {
        .track__title a, .track__index, .track__play .ion, .track__artist, .track__createdat, .track__actions__toggle-menu {
         color: black;
         font-weight: bold;
        }
        .player-playing & {
          .play {
            display: none;
          }
          .pause {
            display: flex;
          }
        }
      }
    }
    &--invalid {
      background-color: $invalidate-color;
      filter: grayscale(50%);
    }
    &--pending {
      background-color: $pending-color;
      filter: grayscale(20%);
    }
    &--invalidate, &--dropped {
      background-color: $invalidate-color;
      filter: grayscale(80%);
      opacity: 0.5;
      height: 0;
      display: none;
    }
    &--editable {
      .track__title, .track__artist {
        cursor: text;
      }
    }
    &__play {
      > * {
        pointer-events: none;
      }
      cursor: pointer;
      .play, .pause {
        position: relative;
        z-index: $z-index-tracks-play;
        margin: 4px;
        margin-left: 8px;
      }
    }
    &__img {
      height: 22px;
      width: 22px;
      position: absolute;
      top: 50%;
      left: 6px;
      transform: translateY(-50%);
      border-radius: 20px;
      z-index: $z-index-tracks;
      object-fit: none;
    }
    &__index {
      text-align: center;
      cursor: pointer;
    }
    td {
      vertical-align: middle;
    }
    &__dot {
      font-size: 34px;
      text-align-last: left;
    }
    &__title {
      padding-left: 8px;
      cursor: pointer;
    }
    &__title-txt {
      &:hover {
        text-decoration: underline;
      }
    }
    &__star {
      color: $favorite-color;
      font-size: 14px;
      @include breakpoint(tablet) {
        display: none;
      }
    }
    &__duration {
      cursor: pointer;
      background-color: white;
      margin-right: 20px;
      float: right;
      padding: 4px;
      border-radius: 8px;
      @include breakpoint(mobile) {
        display: none;
      }
    }
    &__artist {
      @extend %artistStyle;
      &--txt {
        padding: 8px 12px;
        border-radius: 20px;
      }
      &:active, &:focus {
        .track__artist--txt {
          background-color: white;
          color: black;
        }
      }
    }
    &__createdat {
      display: flex;
      align-items: center;
      font-size: 12px;
      padding: 0 8px;
      cursor: pointer;
      @include breakpoint(desktop) {
        // display: table-cell;
      }
    }
    &__user {
      display: none;
      @include breakpoint(phablet) {
        display: table-cell;
      }
      &__link {
        text-align: center;
        display: flex;
        border-radius: 40px;
        overflow: hidden;
        .text-label {
          padding-left: 8px;
        }
      }
    }
    &.open {
      position: relative;
      z-index: $z-index-tracks;
      .track__actions__menu {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        box-shadow: black 0 0 30px;
        border-radius: $dialog-border-radius;
        .track__createdat {
          padding-left: 20px;
          width: 100%;
        }
        .track__user__link {
          display: inline-flex;
        }
        button {
          width: 100%;
          text-align: left;
          .text-label {
            padding-left: 10px;
          }
        }
        @include breakpoint(tablet) {
          border-top: 0;
          box-shadow: none;
          border-radius: none;
          .track__createdat {
            display: inline;
          }
        }
        button {
          display: inline;
          height: 100%;
        }
      }
    }
    &.track--playing {
      .track__actions__menu {
        box-shadow: black 0 30px 30px;
        @include breakpoint(tablet) {
          box-shadow: none;
        }
      }
    }
    &__actions {
      text-align: right;
      position: relative;
      &__toggle-menu {
        font-weight: bold;
        @include breakpoint(tablet) {
          display: none;
        }
      }
      &__menu {
        display: none;
        position: fixed;
        padding: 0;
        top: 50%;
        right: 50px;
        transform: translateY(-50%);
        height: auto;
        padding: 10px;
        background-color: #242424;
        z-index: 200;
        @include breakpoint(tablet) {
          display: inline-flex!important;
          flex-direction: row!important;
          .text-label {
            display: none;
          }
          position: relative;
          background-color: transparent;
          right: 0;
          box-shadow: 0;
          padding: 0;
        }
      }
      .toggle_favorite {
        cursor: pointer;
        color: $favorite-color;
      }
    }
    &:first-child, &:nth-child(2), &:nth-child(3), &:nth-child(4), &:nth-child(5), &:nth-child(6) {
      .track__actions__menu {
        @include breakpoint(phone) {
          top: 0!important;
          transform: translateY(0%)!important;
        }
      }
    }
    &:last-child, &:nth-last-child(2), &:nth-last-child(3) {
      .track__actions__menu {
        @include breakpoint(phone) {
          top: 100%;
          transform: translateY(-100%);
        }
      }
    }
    @include breakpoint(tablet) {
      .track__actions__menu {
        top: 0;
        transform: translateY(0);
      }
    }
  }
}
.no-track {
  width: 100%;
  display: grid;
  align-content: center;
  justify-content: center;
}
