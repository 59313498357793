input[type=checkbox] {
  opacity: 0;
  width: 0;
  height: 0;
  // z-index: 999;
  // position: relative;
  &:checked + .slider {
    background-color: #348247;
  }
  
  &:focus + .slider {
    box-shadow: 0 0 1px #348247;
  }
  
  &:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
}
