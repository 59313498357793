.counter {
  color: black;
  background-color: white;
  font-size: 12px;
  font-style: normal;
  font-weight: bold;
  border-radius: 20px;
  padding: 4px 8px;
  position: absolute;
  right: 10px;
  cursor: default;
  &__pending {
    background-color: $pending-color;
    color: $app-color;
  }
  &__invalid {
    background-color: $invalidate-color;
    color: $app-color;
  }
}
