input, textarea {
  outline: none;
  padding: 10px 0;
  margin: 10px 0;
  border: none;
  // background-color: $ui-bkg;
  color: $app-color;
  border: none;
  padding: 10px;
  text-align: center;
  &:invalid {
    border-left: 3px red solid;
    box-shadow: none;
  }
  &::-moz-placeholder {
    color: grey;
  }
  &:active, &:focus {
    color: $app-color;
  }
}
