@import '~@/styles/preprocessed';

.search {
  display: flex;
  align-items: center;
  position: relative;
    @include breakpoint(tablet) {
  margin-right: 20px;
  }
  &__input {
    background-color: rgb(49, 49, 49);
    border-radius: 20px;
    transition: all 0.3s;
    display: inline-flex;
    width: 0;
    padding: 0;
    @include breakpoint('tablet') {
      width: auto;
      padding: 10px 0;
    }
    body[class*="route-style"] & {
      background-color: rgb(24, 24, 24);
      color: $app-color;
    }
  }
  &__count {
    cursor: pointer;
    @extend %appStyleBkgColor;
    display: none;
    @include breakpoint('tablet') {
      display: inline-flex;
    }
  }
  &__button {
    height: 100%;
    width: $search-button-width;
    z-index: $z-index-search-button;
    position: relative;
    @include breakpoint('tablet') {
      display: none;
    }
  }
}
