@import '~@/styles/preprocessed';

.mail {
  margin: 20px;
  label {
    margin-top: 10px;
    padding: 10px 0;
    display: inline-block;
  }
  &__body {
    @include breakpoint(tablet) {
      display: flex;
      justify-content: space-around;
    }
  }
  &__contacts {
    transition: height .3s;
    height: 0;
    max-height: 240px;
    overflow: hidden;
    background-color: rgb(24, 24, 24);
    &--open {
      height: 40vh;
      overflow: auto;
    }
  }
  &__search {
    position: sticky;
    top: 0;
    background-color: rgb(40, 40, 40)!important;
  }
  &__contact {
    padding: 4px; 
    display: flex;
    align-items: center;
    &:hover {
      background-color: white;
      color: black;
    }
    .avatar {
      margin: 0 12px;
    }
  }
  &__subject {
    text-align-last: left;
  }
  &__form {
    min-width: 350px;
    @include breakpoint(tablet) {
      margin-right: 20px;
    }
    .content {
      text-align: left;
    }
  }
  &__page {
    width: 100%;
  }
  &__preview {
    max-width: 490px;
    background-color: white;
    border: rgb(218, 218, 218) 20px solid;
    padding: 20px;
    width: 100%;
    h1, h2, h3, h4, h5, h6 {
      color: #464646;
    }
    .hero-subheader__content {
      color: grey;
      p {
        color: grey;
      }
      a {
        color: #23d18b;
        text-decoration: underline;
      }
    }
    .container {
      width: 100%;
      text-align: center;
    }
    .center {
      text-align: center;
      width: 100%;
    }
    .style__link {
      padding: 4px 10px;
      margin-right: 14px;
      color: white;
    }
  }
  &__archives {
    td {
      padding: 20px;
      cursor: pointer;
    }
    tr {
      &:hover {
        color: black;
        background-color: $primary-color;
      }
    }
  }
  button {
    width: 100%;
    margin-top: 30px;
    margin-bottom: 40px;
  }
}
