@import '~@/styles/preprocessed';

.header {
  // @extend %appStyleBkgColor;
  background-color: $app-bkg;
  // background-color: $header-bkg;
  box-shadow: $app-bkg 0 0 30px;
  position: fixed;
  z-index: $z-index-header;
  top: 0;
  width: 100%;
  height: $header-height;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  .nav-toggle {
    z-index: $z-index-header-elt;
    @include breakpoint(tablet) {
      padding: 0;
    }
    .icon-nav {
      @include breakpoint(tablet) {
        padding: 0 20px;
      }
    }
    &--online {
      @include breakpoint(tablet) {
        width: $nav-width;
      }
    }
    &__name {
      display: none;
      @include breakpoint('tablet') {
        display: flex;
      }
      // .app[class*='route-style-'] & {
      //   color: black;
      // }
    }
  }
  .nav-toggle, .logo__name, .search__button svg {
    color: $app-color;
    // body[class*="route-style"] & {
    //   color: black;
    // }
  }
  .nav-toggle, .search__button {
    font-size: 24px;
  }
}
