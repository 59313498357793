@mixin breakpoint($breakpoint, $only: "") {
 
  @if $breakpoint == phone {
    @media (max-width: 768px) { @content; }
  }

  @else if $breakpoint == phablet {
    @if $only == only {
      @media (min-width: 390px) and (max-width: 768px) { @content; }
    }
    @else {
      @media (min-width: 390px) { @content; }
    }
  }

  @else if $breakpoint == tablet {
    @if $only == only {
      @media (min-width: 768px) and (max-width: 992px) { @content; }
    }
    @else {
      @media (min-width: 768px) { @content; }
    }
  }

  @else if $breakpoint == desktop {
    @if $only == only {
      @media (min-width: 992px) and (max-width: 1200px) { @content; }
    }
    @else {
      @media (min-width: 992px) { @content; }
    }
  }

  @else if $breakpoint == bigscreen {
    @media (min-width: 1200px) { @content; }
  }

  @else {
    @warn "The breakpoint mixin supports the following attributes: phone, phablet, tablet, desktop, bigscreen";
  }
}
