@import '~@/styles/preprocessed';

.users {
  justify-content: center;
  .loader {
    width: 50px;
    height: 50px;
  }
  .search-filter {
    color: black;
    border-radius: 20px;
    &__label {
      margin-right: 20px;
      position: relative;
      .counter {
        top: 18px;
      }
    }
  }
  .dock--mobile-open {
    display: grid;
    @include breakpoint(tablet) {
      display: flex;
    }
  }
  .user-list {
    padding-top: $header-height;
    border-radius: 26px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 60px;
    margin: 10px;
    .card {
      flex: 1 1 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
      text-align: center;
    }
  }
}
