@import '~@/styles/preprocessed';

.upload {
  width: 100%;
  margin: 10px 0;
  &__input {
    display: none;
  }
  &__button {
    width: 100%;
  }
}
