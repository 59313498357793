.dock {
  position: fixed;
  z-index: $z-index-pages-ui;
  top: $header-height;
  display: none;
  @include breakpoint(tablet) {
    display: flex;
  }
  &--mobile-open {
    display: flex;
  }
  .app[data-dialog='true'] & {
    z-index: $z-index-zero;
  }
  align-items: center;
  justify-content: space-between;
  background-color: $app-bkg;
  box-shadow: $app-bkg 0 0 30px;
  padding: 2px 16px;
  border-radius: 0 0 20px 20px;
  .checkbox {
    padding: 8px;
    margin-right: 4px;
    transition: .3s all;
    cursor: pointer;
    &:hover {
      background-color: #91919163;
      border-radius: 10px;
    }
  }
  button {
    cursor: normal;
    opacity: 0.2;
    transition: opacity 1s;
    &.enable {
      cursor: pointer;
      opacity: 1;
    }
  }
  button {
    .checkbox {
      margin: 0 8px;
    }
  }
}

.dock-toggle {
  position: fixed;
  display: block;
  right: 0;
  z-index: $z-index-pages-ui;
  top: $header-height;
  @include breakpoint(tablet) {
    display: none;
  }
}