@import '~@/styles/preprocessed';

.recover-password {
  width: 100%;
  margin: 0 60px;
  margin-top: 140px;
  display: flex;
  justify-content: center;
  .message {
    padding: 16px;
    text-transform: capitalize;
  }
  label {
    text-align: left;
    width: 100%;
  }
}
