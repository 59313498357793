@import '~@/styles/preprocessed';

.channel {
  cursor: pointer;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  overflow: hidden;
  border-bottom: 1px rgba(255, 255, 255, 0.1) solid;
  transition: all 0.2s;
  padding: 4px 0;
  background-color: $app-bkg;
  &:hover {
    background-color: $color-selection;
  }
  &__name {
    flex-grow: 1;
    margin: 0;
    padding: 40px;
  }
  &__name, &__link {
    display: flex;
    align-items: center;
  }
  &__link {
    text-decoration: underline;
  }
  > * {
    padding: 2px 6px;
  }
  &__official {
    background-color: $validate-color;
    border-radius: 20px;
    padding: 2px;
    margin-left: 20px;
    height: 14px;
    width: 14px;
    svg {
      margin-top: -2px;
    }
  }
}
