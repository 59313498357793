progress {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  height: $global-ui-bar-height;
  transition: height .3s;
  background-color: transparent;
  &:hover {
    height: $global-ui-bar-height-hover;
    // &::-moz-progress-bar {
    //   @extend %currentStyleBkgColor;
    // }
    // &::-webkit-progress-value {
    //   @extend %currentStyleBkgColor;
    // }
  }
  // this is not the bar, it's the all progress element :
  &::-webkit-progress-bar {
    background-color: transparent;
  }
  // &::-moz-progress-bar {
  //   @extend %currentStyleBkgColor;
  // }
  // &::-webkit-progress-value {
  //   @extend %currentStyleBkgColor;
  // }
  .current-style-1 & {
    &::-moz-progress-bar {
      background-color: $color-1;
    }
    &::-webkit-progress-value {
      background-color: $color-1;
    }
  }
  .current-style-2 & {
    &::-moz-progress-bar {
      background-color: $color-2;
    }
    &::-webkit-progress-value {
      background-color: $color-2;
    }
  }
  .current-style-3 & {
    &::-moz-progress-bar {
      background-color: $color-3;
    }
    &::-webkit-progress-value {
      background-color: $color-3;
    }
  }
}

.progress-bar {
  &:hover {
    progress {
      height: $global-ui-bar-height-hover;
    }
  }
}