.typo-one-line {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.typo-center {
  text-align: center;
}
.link {
  &:hover {
    text-decoration: underline;
  }
}
