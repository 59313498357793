@import '~@/styles/preprocessed';

.card {
  display: table;
  border-radius: 26px;
  margin: 10px;
  &__link {
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: all .3s;
    &:hover {
      .card__avatar {
        box-shadow: white 0 0 4px;
      }
      .card__name {
        text-decoration: underline;
      }
    }
    &:active {
      position: relative;
      top: 2px;
    }
  }
  &__figure {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    text-align: center;
  }
  &__avatar {
    width: 100px;
    height: 100px;
    border-radius: 300px;
  }
  &__name {
    font-style: normal;
  }
  &__nb-track {
    display: flex;
    align-items: center;
    font-weight: bold;
    background-color: beige;
    color: black;
    border-radius: 16px;
    padding: 2px 4px;
    font-size: 12px;
    margin-top: -10px;
    .ion {
      margin-left: 4px;
    }
    &:hover {
      text-decoration: none!important;
      box-shadow: white 0 0 4px;
    }
  }
}
