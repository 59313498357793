$z-index-dialog:          100;
$z-index-player:           90;
$z-index-nav:              80;
$z-index-logo:             71;
$z-index-header:           70;
$z-index-search-button:    64;
$z-index-header-elt:       60;
$z-index-pages-ui:         24;
$z-index-pages:            20;
$z-index-tracks-play:      12;
$z-index-tracks:           10;
$z-index-zero:              0;
