@import '~@/styles/preprocessed';

.add-tracks {
  &__form {
    // background-color: #202020;
    @include breakpoint('tablet') {
      border-radius: 0;
      border-bottom-left-radius: $dialog-border-radius;
      border-bottom-right-radius: $dialog-border-radius;
    }
  }
  &__iframe {
    text-align: center;
    height: 100px;
    min-height: 0;
    @include breakpoint('tablet') {
      border-radius: 0;
      border-top-left-radius: $dialog-border-radius;
      border-top-right-radius: $dialog-border-radius;
    }
  }
  &__original-title {
    color: $app-color;
    padding-bottom: 12px;
    text-align: center;
  }
}
