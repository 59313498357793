button, .button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  outline: 0;
  border: none;
  color: $app-color;
  background-color: transparent;
  cursor: pointer;
  padding: 18px;
  border-radius: 4px;
  &:active {
    opacity: 0.5;
  }
  &:focus {
    background: #ffffff14;
  }
  &:hover, &:active {
    background-color: rgba(255, 255, 255, 0.068);
  }
  &.edit {
    &:hover, &:active {
      background-color: $edit-color;
    }
  }
  &.validate {
    &:hover, &:active {
      background-color: $validate-color;
    }
  }
  &.drop {
    &:hover, &:active {
      background-color: $invalidate-color;
    }
  }
  &.youtube {
    background-color: $youtube-red!important;
  }
  &.cta {
    background-color: $app-color;
    color: black;
    font-weight: bold;
    &:hover, &:active {
      background-color:$grey-0;
      color: $app-color;
    }
  }
  .ion {
    padding-right: 10px;
  }
}
