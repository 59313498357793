@import '~@/styles/preprocessed';

.styleSelector {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  .selected span {
    color: $app-color;
  }
}
