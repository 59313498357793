@import '~@/styles/preprocessed';

.login {
  .avatar {
    .route-signup & {
      cursor: pointer;
    }
  }
  &__form, &__sent {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .loader {
    width: 50px;
    height: 50px;
  }
  .actions {
    display: flex;
    width: 100%;
  }
  &__button {
    text-transform: uppercase;
    margin-top: 8px;
    width: 100%;
    border-radius: 0 0 10px 10px;
    &:hover {
      color: $app-color;
    }
  }
  &__avatar {
    margin-top: 20px;
  }
  &__sign {
    margin: 20px 0;
    text-align: center;
    &:focus {
      background-color: transparent;
      outline: none;
      border: none;
    }
  }
  &__forgot {
    cursor: pointer;
    font-size: 12px;
    text-decoration: underline;
  }
  &__sent {
    color: white;
    padding: 60px 30px;
    line-height: 24px;
    text-align: center;
    a {
      margin-top: 20px;
      cursor: pointer;
    }
    .back-button {
      margin-top: 20px;
    }
  }
}
