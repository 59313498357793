.style-1 {
  color: $color-1;
}
.style-2 {
  color: $color-2;
}
.style-3 {
  color: $color-3;
}

.style-bkg-1, .style-bkg-1:focus, .style-bkg-1.track--playing, .style-bkg-1.track--playing .track__actions__menu {
  background-color: darken($color-1, 8%);
}
.style-bkg-2, .style-bkg-2:focus, .style-bkg-2.track--playing, .style-bkg-2.track--playing .track__actions__menu {
  background-color: darken($color-2, 8%);
}
.style-bkg-3, .style-bkg-3:focus, .style-bkg-3.track--playing, .style-bkg-3.track--playing .track__actions__menu {
  background-color: darken($color-3, 8%);
}

.style-hover-1 {
  &.router-link-active, &:hover {
    color: $color-1;
  }
}
.style-hover-2 {
  &.router-link-active, &:hover {
    color: $color-2;
  }
}
.style-hover-3 {
  &.router-link-active, &:hover {
    color: $color-3;
  }
}

.style-hover-bkg-1 {
  &.router-link-active, &::before, &:hover {
    background-color: $color-1;
  }
}
.style-hover-bkg-2 {
  &.router-link-active, &::before, &:hover {
    background-color: $color-2;
  }
}
.style-hover-bkg-3 {
  &.router-link-active, &::before, &:hover {
    background-color: $color-3;
  }
}

%appStyleColor {
  transition: all 0.3s;
  color: $primary-color;
  fill: $primary-color;
  .route-style-1 & {
    color: $color-1;
    fill: $color-1;
  }
  .route-style-2 & {
    color: $color-2;
    fill: $color-2;
  }
  .route-style-3 & {
    color: $color-3;
    fill: $color-3;
  }
}

%currentStyleColor {
  transition: all 0.3s;
  color: $primary-color;
  fill: $primary-color;
  .current-style-1 & {
    color: $color-1;
    fill: $color-1;
  }
  .current-style-2 & {
    color: $color-2;
    fill: $color-2;
  }
  .current-style-3 & {
    color: $color-3;
    fill: $color-3;
  }
}

%appStyleBkgColor {
  transition: background-color 0.3s;
  .route-style-1 & {
    background-color: $color-1;
  }
  .route-style-2 & {
    background-color: $color-2;
  }
  .route-style-3 & {
    background-color: $color-3;
  }
}

%currentStyleBkgColor {
  transition: background-color 0.3s;
  background-color: $primary-color;
  .current-style-1 & {
    background-color: $color-1;
  }
  .current-style-2 & {
    background-color: $color-2;
  }
  .current-style-3 & {
    background-color: $color-3;
  }
}

%appStyleStroke {
  transition: stroke 0.3s;
  stroke: $primary-color;
  .route-style-1 & {
    stroke: $color-1;
  }
  .route-style-2 & {
    stroke: $color-2;
  }
  .route-style-3 & {
    stroke: $color-3;
  }
}

%currentStyleStroke {
  transition: stroke 0.3s;
  stroke: $primary-color;
  .current-style-1 & {
    stroke: $color-1;
  }
  .current-style-2 & {
    stroke: $color-2;
  }
  .current-style-3 & {
    stroke: $color-3;
  }
}

%artistStyle {
  cursor: pointer;
  color: $primary-color;
  // font-weight: bold;
  &:hover {
    text-decoration: underline;
  }
}
