@import '~@/styles/preprocessed';

.user {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @include breakpoint(desktop) {
    flex-direction: row;
  }
  &.loader {
    width: 50px;
    height: 50px;
  }
  .nb-tracks {
    font-size: 22px;
    text-decoration: none;
    .play {
      padding-right: 10px;
    }
    .count {
      margin-right: 10px;
    }
  }
  &__name {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  &__image-container {
    display: flex;
    flex-direction: column;
    button {
      font-weight: bold;
      margin-top: 24px;
      text-transform: uppercase;
    }
  }
  &__captions {
    padding: 10px;
    text-align: center;
    @include breakpoint(desktop) {
      padding-left: 40px;
    }
    p {
      text-align: left;
      font-size: 18px;
      line-height: 200%;
    }
  }
  &__go-back {
    display: block;
  }
  .sync-text {
    margin-left: 12px;
    font-size: 18px;
    font-weight: bold;
  }
  &__sync-button {
    .ion {
      .loading & {
        display: none;
      }
    }
  }
  &__link {
    display: block;
    padding: 20px;
    text-decoration: underline;
  }
  .loader {
    margin-left: 0!important;
    margin-right: 8px;
  }
}
