@import '~@/styles/preprocessed';

.about {
  flex-direction: column;
  a {
    text-decoration: underline;
    &:nth-child(3n) {
      color: $color-1;
    }
    &:nth-child(3n+1) {
      color: $color-2;
    }
    &:nth-child(3n+2) {
      color: $color-3;
    }
  }
  svg {
    width: 100%;
    height: 20vw;
    padding: 20px;
    padding-bottom: 40px;
  }
  .card {
    margin-top: 120px;
    margin-bottom: 120px;
    @include breakpoint(desktop) {
      margin: 120px;
    }
  }
}
