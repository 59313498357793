form {
  .item {
    width: 90%!important;
  }
  .actions {
    width: 100%;
    margin-top: 20px;
    background-color: rgb(24, 24, 24);
    border-radius: 0 0 10px 10px;
    > button {
      width: 50%;
      text-transform: uppercase;
      &:first-child {
        border-radius: 0 0 0 9px;
      }
      &:last-child {
        border-radius: 0 0 9px 0;
      }
    }
  }
}
