@import '~@/styles/preprocessed';

.user {
  &--edit {
    .avatar {
      cursor: pointer;
    } 
  }
  &__drop {
    float: right;
    text-transform: uppercase;
  }
}
