dialog {
  z-index: $z-index-dialog;
  display: flex;
  flex-direction: column;
  height: 100vh;
  position: fixed;
  top: 0;
  pointer-events: none;
  opacity: 0;
  position: fixed;
  z-index: $z-index-dialog;
  justify-content: center;
  align-items: center;
  width: 100vw;
  padding: 0;
  margin: 0!important;
  border: none;
  transition: all .3s!important;
  background-color: transparent;
  &:target, .app[data-dialog='true'] & {
    pointer-events: all;
    opacity: 1;
  }
  > div {
    position: relative;
    display: block;
    width: 90%;
    background-color: $app-bkg;
    z-index: $z-index-dialog;
    display: flex;
    flex-direction: column;
    border-radius: $dialog-border-radius;
    @include breakpoint('tablet') {
      width: $dialog-width;
      box-shadow: 0 0 10px black;
    }
  }
}
