@import '~@/styles/preprocessed';

.avatar {
  border-radius: 100%;
  object-fit: cover;
  &--small {
    width: 30px;
    height: 30px;
  }
  &--medium {
    width: 100px;
    height: 100px;
  }
  &--big {
    width: 300px;
    height: 300px;
  }
}
