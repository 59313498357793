@import '~@/styles/preprocessed';

.add-bots {
  &__form {
    // background-color: #202020;
    @include breakpoint('tablet') {
      border-radius: 0;
      border-bottom-left-radius: $dialog-border-radius;
      border-bottom-right-radius: $dialog-border-radius;
    }
  }
  &__avatar {
    padding: 20px;
  }
}
