@import '~@/styles/preprocessed';

.edit-track {
  &--explorer-open {
    height: 50vh;
    overflow-y: scroll;
  }
  &__link {
    padding: 8px;
    &:hover {
      text-decoration: underline;
    }
  }
  &__style {
    margin-top: 10px;
  }
  .explorer {
    min-height: auto;
    margin-bottom: 20px;
    &__search {
      top: 0;
    }
    .track__createdAt {
      color: grey;
      font-size: 10px;
      overflow: visible;
      white-space: wrap;
    }
  }
}
