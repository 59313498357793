@import '~@/styles/preprocessed';

.tester {
  position: sticky;
  .testButton {
    background-color: rgba(255, 255, 255, 0.103);
    margin-right: 10px;
  }
}
.track {
  position: relative;
}
