@import '~@/styles/preprocessed';

.app {
  display: flex;
  overflow-x: hidden;
  .page {
    z-index: $z-index-tracks;
    width: 100%;
    // min-height: calc(100vh - #{$header-height} - #{$player-height});
    display: flex;
    transition: all 0.2s;
    & > *:first-child {
      // min-height: calc(100vh - 75px);
      margin-top: $header-height;
      margin-bottom: 0;
      .app--player {
        margin-bottom: $player-height;
      }
    }
    &--container {
      width: $page-width-phablet;
      @include breakpoint(tablet) {
        width: $page-width-tablet;
      }
      @include breakpoint(desktop) {
        width: $page-width-desktop;
      }
      @include breakpoint(bigscreen) {
        width: $page-width-bigscreen;
      }
      margin: 0 auto;
      min-height: 100vh;
    }
  }
  &--nav {
    overflow-y: hidden;
    @include breakpoint(tablet) {
      overflow-y: auto;
    }
    .nav {
      transform: translateX(0%);
    }
    .page {
      padding-left: $nav-width;
    }
  }
  &--search {
    .logo {
      display: none;
    }
    .search {
      flex-grow: 1;
      justify-content: right;
      &__input {
        display: block;
        flex-grow: 1;
        margin: 0 10px;
        padding: 10px 0;
      }
      &__count {
        display: inline-block;
        right: 80px;
      }
    }
  }
  &--player {
    .page {
      margin-bottom: $player-height;
    }
    .player {
      transform: translateY(0%);
      height: auto;
    }
    .nav {
      height: calc(100vh - #{$header-height} - #{$player-height});
      min-height: calc(100vh - #{$header-height} - #{$player-height});
    }
  }
  &--full {
    overflow: hidden;
    .header, .player__bottom {
      background-color: #00000075;
    }
    .header {
      z-index: $z-index-logo + 20;
      .nav-toggle, .search {
        display: none;
      }
      .logo {
        width: 100%;
        justify-content: center;
      }
    }
    .player {
      height: 100vh;
      bottom: 0;
      &__top {
        position: fixed;
        width: 100%;
        height: calc(100vh - #{$player-height});
        top: 0;
        #player {
          height: 100vh;
        }
      }
      &__bottom {
        position: fixed;
        width: 100%;
        bottom: 0;
        .player-infos {
          text-shadow: black 0px 0px 1px;
        }
      }
      .player-next {
        width: 44px;
        height: 64px;
        border: auto;
        svg {
          width: auto;
        }
      }
    }
    .up-down-icon {
      transform: rotateX(180deg);
    }
  }
  &[data-dialog='true'] {
    overflow: hidden!important;
    .player {
      opacity: 0;
    }
    .page {
      z-index: $z-index-dialog;
    }
  }
}
