@import '~@/styles/preprocessed';

.nav {
  top: $header-height;
  height: calc(100vh - #{$header-height});
  min-height: calc(100vh - #{$header-height});
  z-index: $z-index-nav;
  position: fixed;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
  flex-direction: column;
  background-color: $app-bkg;
  // background-color: $header-bkg;
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  will-change: transform;
  transition-property: transform, visibility, width;
  transform: translateX(-100%);
  width: 100%;
  @include breakpoint(tablet) {
    width: $nav-width;
  }
  &__link {
    flex-grow: 1;
    display: flex;
    color: $app-color;
    width: 100%;
    cursor: pointer;
    &__txt {
      flex-grow: 0.9;
    }
    &:hover {
      text-decoration: none;
    }
    @include breakpoint(tablet) {
      width: auto;
      justify-content: flex-start;
    }
    align-items: center;
    padding: 14px 16px!important;
    font-size: 18px;
    @include breakpoint(tablet) {
      font-size: 16px;
    }
    text-transform: capitalize;
    &--user {
      border-top: 1px rgba(255, 255, 255, 0.1) solid;
    }
    &--add-tracks.router-link-exact-active {
      .ion svg {
        fill: $validate-color;
      }
    }
    &--favorite.router-link-exact-active {
      .ion svg {
        fill: $favorite-color;
      }
    }
    &--style {
      &::before {
        content: "";
        height: 6px;
        width: 6px;
        border-radius: 6px;
        margin: 0 6px;
        margin-right: 16px;
      }
    }
    &--sub {
      padding-left: 40px!important;
    }
    .sub-icon {
      float: right;
    }
    .ion, .avatar {
      margin-right: 10px;
    }
    .avatar {
      width: 16px;
      height: 16px;
      border-radius: 16px;
    }
    &:last-child {
      margin-bottom: 20px;  // fix player over nav
    }
    &:active {
      opacity: 0.5;
    }
    &:hover,
    &.router-link-exact-active {
      color: black;
      background-color: #e7e7e7;
      font-weight: bold;
      &::before {
        background-color: black;
      }
    }
  }
}
