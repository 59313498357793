@import '~@/styles/preprocessed';

.loader {
  pointer-events: none;
  display: none;
  width: 100%;
  height: 100%;
  display: flex;
  .control-bar & {
    position: absolute;
    top: 0;
    bottom: 23px;
  }
  .player-buffering .control-bar & {
    opacity: 1;
    display: block;
    height: 100%;
    width: 100%;
    margin: 0;
  }
  button & {
    width: 19px;
    height: 19px;
    margin-left: 10px;
  }
}
