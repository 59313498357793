// vars
$input-range-container-height: 40px;
$input-range-container-bkg-color: transparent;
$input-range-bar-bkg-color: transparent;
$input-range-bar-progress-bkg-color: transparent;
$input-range-bar-progress-bkg-color-hover: transparent;
$input-range-pointer-size: 12px;
$input-range-pointer-bkg-color: $third-color;

@mixin input-range-container {
  -webkit-appearance: none;
  appearance: none;
  padding: 0;
  margin: 0;
  background-color: $input-range-container-bkg-color;
  height: $input-range-container-height;
}
@mixin input-range-bar {
  -webkit-appearance: none;
  appearance: none;
  background-color: $input-range-bar-bkg-color;
  border-radius: 0;
  height: $global-ui-bar-height;
  border: none;
  color: transparent;
}
@mixin input-range-bar-hover {
  height: $global-ui-bar-height-hover;
}
@mixin input-range-bar-progress {
  background-color: $input-range-bar-progress-bkg-color;
  transition: height .3s, width .3s, background-color .3s;
  height: $global-ui-bar-height;
  margin: 0;
  padding: 0;
  border: none;
  -webkit-appearance: none;
  appearance: none;
}
@mixin input-range-bar-progress-hover {
  background-color: $input-range-bar-progress-bkg-color-hover;
  transition: height .3s, width .3s, background-color .3s;
  height: $global-ui-bar-height-hover;
}
@mixin input-range-pointer {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  height: $input-range-pointer-size;
  width: $input-range-pointer-size;
  border-radius: 50%;
  background-color: $input-range-pointer-bkg-color;
  transition: all .3s;
  transform: scale(0, 0);
  transform-origin: center;
}
@mixin input-range-pointer-hover {
  transform: scale(1, 1);
}

input[type=range] {
  @include input-range-container;
  &::-webkit-slider-runnable-track {
    @include input-range-bar;
  }
  &::-moz-range-track {
    @include input-range-bar;
  }
  &::-ms-track {
    @include input-range-bar;
  }
  &::-moz-range-progress {
    @include input-range-bar-progress;
  }
  &::-ms-fill-lower {
    @include input-range-bar-progress;
  }
  &::-webkit-slider-thumb {
    @include input-range-pointer;
    margin-top: -3px;
  }
  &::-moz-range-thumb {
    @include input-range-pointer;
  }
  &::-ms-thumb {
    @include input-range-pointer;
    margin-top: -2px;
  }
  // thumb
  .current-style-1 & {
    &::-moz-range-thumb {
      background-color: $color-1;
    }
    &::-webkit-slider-thumb {
      background-color: $color-1;
    }
    &::-ms-thumb {
      background-color: $color-1;
    }
  }
  .current-style-2 & {
    &::-moz-range-thumb {
      background-color: $color-2;
    }
    &::-webkit-slider-thumb {
      background-color: $color-2;
    }
    &::-ms-thumb {
      background-color: $color-2;
    }
  }
  .current-style-3 & {
    &::-moz-range-thumb {
      background-color: $color-3;
    }
    &::-webkit-slider-thumb {
      background-color: $color-3;
    }
    &::-ms-thumb {
      background-color: $color-3;
    }
  }
  &:hover, .touch & {
    &::-webkit-slider-runnable-track {
      @include input-range-bar-hover;
    }
    &::-moz-range-track {
      @include input-range-bar-hover;
    }
    &::-ms-track {
      @include input-range-bar-hover;
    }
    &::-moz-range-progress {
      @include input-range-bar-progress-hover;
    }
    &::-ms-fill-lower {
      @include input-range-bar-progress-hover;
    }
    &::-webkit-slider-thumb {
      @include input-range-pointer-hover;
    }
    &::-moz-range-thumb {
      @include input-range-pointer-hover;
    }
    &::-ms-thumb {
      @include input-range-pointer-hover;
    }
  }
}
