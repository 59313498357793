$primary-color: rgb(194, 194, 194);
$secondary-color: rgb(27, 18, 18);
$third-color: grey;

$color-selection: rgba(128, 128, 128, 0.164);
$edit-color: rgba(255, 145, 0, 0.842);
$invalidate-color: rgba(179, 0, 54, 0.555);
$validate-color: rgba(0, 179, 81, 0.555);
$pending-color: rgba(251, 255, 0, 0.123);
$favorite-color: rgb(255, 191, 72);
$youtube-red: rgb(255, 0, 0);

$color-1: #3b8eea;
$color-2: #23d18b;
$color-3: #e12215;

$grey--1: #4040405e;
$grey-0: #404040;
$grey-1: #505050;
$grey-2: #606060;
$grey-3: #707070;
$grey-4: #808080;
$grey-5: #909090;
$grey-6: #A0A0A0;

$header-bkg: transparent;
$app-bkg: rgb(0, 0, 0);
$header-bkg: rgb(20, 20, 20);
$ui-bkg: rgba(0, 0, 0, 0.95);

$app-color: rgb(231, 231, 231);

$scrollbar-color: #2c2c2c;
$scrollbar-background-color: #131313;
