@import '~@/styles/preprocessed';

.user-profile {
  &__container {
    display: flex;
    justify-content: center;
    width: 100%;
    @include breakpoint(tablet) {
      display: block;
    }
  }
}
