@import '~@/styles/preprocessed';

.track {
  cursor: pointer;
  display: flex;
  align-items: center;
  overflow: hidden;
  border-bottom: 1px rgba(255, 255, 255, 0.1) solid;
  transition: all 0.2s;
  height: 50px;
  padding: 4px 0;
  background-color: $app-bkg;
  &:hover {
    background-color: $color-selection;
  }
  > * {
    padding: 2px 6px;
  }
  &__index,
  &__title {
    color: $app-color;
  }
  &__artist {
    font-weight: bold;
    &:hover {
      text-decoration: none;
    }
  }
  &__thumb {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    &__pic {
      width: 60px;
    }
    &__cap {
      position: absolute;
      color: $app-color;
      font-size: 12px;
      background-color: rgba(0, 0, 0, 0.74);
      border-radius: 14px;
      padding: 2px 6px;
    }
  }
  &__createdAt {
    flex-grow: 1;
    text-align: right;
  }
}
